import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b6f24042"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-item-artwork" }
const _hoisted_2 = { class: "thumbnail-wrapper border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slot_error = _resolveComponent("slot-error")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_UploadArtwork = _resolveComponent("UploadArtwork")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_col, {
        md: 24,
        xs: 24,
        class: "p-2 cursor-pointer relative",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openOnNewTab(_ctx.item.imageSrc)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_image, {
            src: _ctx.imageUrl(_ctx.item.imageSrc, true, 400),
            class: "image-wrapper",
            title: "Open in new tab"
          }, {
            error: _withCtx(() => [
              _createVNode(_component_slot_error)
            ]),
            _: 1
          }, 8, ["src"])
        ]),
        _: 1
      })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.artworkSpaces, (artwork) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "p-1 order-artwork h-100 border",
        key: artwork
      }, [
        (_ctx.item[artwork])
          ? (_openBlock(), _createBlock(_component_el_col, {
              key: 0,
              md: 24,
              xs: 24,
              class: "p-2 cursor-pointer relative",
              onClick: ($event: any) => (_ctx.openOnNewTab(_ctx.item[artwork]))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_image, {
                  src: _ctx.imageUrl(_ctx.item[artwork], true, 300),
                  class: "image-wrapper",
                  title: "Open in new tab"
                }, {
                  error: _withCtx(() => [
                    _createVNode(_component_slot_error)
                  ]),
                  _: 2
                }, 1032, ["src"])
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : (_openBlock(), _createBlock(_component_UploadArtwork, {
              key: 1,
              onUploaded: _ctx.uploaded,
              artworkName: artwork,
              itemIndex: _ctx.index,
              disableEdit: _ctx.disableEditStatus,
              class: "order-artwork-upload"
            }, null, 8, ["onUploaded", "artworkName", "itemIndex", "disableEdit"]))
      ]))
    }), 128))
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus = _resolveComponent("plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createBlock(_component_el_upload, {
    class: "avatar-uploader",
    "auto-upload": false,
    "show-file-list": false,
    "before-upload": _ctx.beforeAvatarUpload,
    "on-change": _ctx.uploadDesign,
    disabled: _ctx.disableEditStatus,
    title: _ctx.artworkName
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_icon, { class: "avatar-uploader-icon" }, {
        default: _withCtx(() => [
          _createVNode(_component_plus)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["before-upload", "on-change", "disabled", "title"]))
}
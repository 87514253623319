
import { defineComponent, ref } from 'vue'
import { Plus } from '@element-plus/icons'
import { useNotification } from '@/composables'
import { validateImageFileUploaded } from '@/utils/file'
import useUploadRepositories from '@/repositories/useUploadRepositories'

export default defineComponent({
  components: {
    Plus,
  },
  props: {
    itemIndex: {
      type: Number,
      required: true,
    },
    artworkName: {
      type: String,
      required: true,
    },
    disableEdit: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const imageUrl = ref('')
    const presignedUrl = ref('')

    const disableEditStatus = ref(props.disableEdit)

    const { onUploadImage } = useUploadRepositories()
    const { error } = useNotification()
    const uploadDesign = async (file: any, fileList: any) => {
      try {
        if (validateImageFileUploaded(file)) {
          const res = await onUploadImage(file.raw)
          emit('uploaded', {
            path: res?.path,
            artwork: props.artworkName,
            index: props.itemIndex,
          })
        }
      } catch (err: any) {
        error(err.message)
      }
    }

    return {
      imageUrl,
      presignedUrl,
      disableEditStatus,
      uploadDesign,
    }
  },
})


import { defineComponent, ref } from 'vue'
import UploadArtwork from '@/components/common/UploadArtwork.vue'
import SlotError from '@/components/common/SlotError.vue'
import { imageUrl } from '@/utils/image'
import { copyToClipboardV2 } from '@/utils/string'

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
    disableEdit: {
      type: Boolean,
    },
  },
  components: {
    UploadArtwork,
    SlotError,
  },
  setup(props, { emit }) {
    const disableEditStatus = ref(props.disableEdit)
    const uploaded = async (uploaded: any) => {
      emit('uploaded', uploaded)
    }

    const artworkSpaces = ref(['artwork1', 'artwork2', 'artwork3', 'artwork4'])

    const openOnNewTab = (url: string) => {
      const fullUrl = imageUrl(url)
      window.open(fullUrl, '_blank')
    }

    return {
      uploaded,
      artworkSpaces,
      imageUrl,
      disableEditStatus,
      copyToClipboardV2,
      openOnNewTab,
    }
  },
})


import moment from 'moment'
import { defineComponent, ref, computed, reactive, watch, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useRoute, useRouter } from 'vue-router'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { useNotification } from '@/composables'
import { imageUrl, getFullUrlLattePrint } from '@/utils/image'

import {
  formatDateTime,
  formatTime,
  shortcutsDatetimePicker,
} from '@/utils/dateTime'
import Pagination from '@/components/common/Pagination.vue'
import FilterTable from '@/components/common/FilterTable.vue'
import { ArrowDown, InfoFilled, Warning, Refresh } from '@element-plus/icons'
import OrderImagePreview from '@/components/common/OrderImagePreview.vue'
import { formatterDollars } from '@/utils/formatter'
import {
  getOrderStatusColor,
  getAddressStatusColor,
  getTrackingOfOrder,
  validateOrderAction,
  getWarningContent,
  isDesignerManager,
  isDesignerAssignment,
} from '@/utils/orderUtils'
import {
  statusTitleMap,
  designServiceTitleMap,
  statusListAgency,
  hireDesignStatus,
  ADDRESS_STATUS,
  addressTitleMap,
  HIRE_DESIGN_STATUS,
} from '@/utils/constants'
import { copyToClipboardV2 } from '@/utils/string'
import useUserRepositories from '@/repositories/useUserRepositories'
import { UserTypes } from '@/utils/types'
import { useFetchData } from '@/composables'
import axios from '@/services/axios'
import SelectSellerTeam from '@/components/common/SelectSellerTeam.vue'
import { AuthorityEnum } from '@/utils/userUtils'
import HireDesignStatusTag from '@/components/orders/display/hire-design/HireDesignStatusTag.vue'

interface FileItem {
  uid: string
  name?: string
  status?: string
  response?: string
  url?: string
  preview?: string
  originFileObj?: any
  file: string | Blob
  raw: string | Blob
}

export default defineComponent({
  components: {
    sectionLayoutContent,
    Pagination,
    FilterTable,
    OrderImagePreview,
    ArrowDown,
    InfoFilled,
    Warning,
    Refresh,
    SelectSellerTeam,
    HireDesignStatusTag,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()
    const { success, error, warning } = useNotification()

    let isShowModalImport = ref<boolean>(false)
    const breadcrumbs = ['Upload designs', '']

    const content = computed(() => {
      return {
        breadcrumbs,
      }
    })

    const userInfo = ref()
    try {
      userInfo.value = JSON.parse(localStorage?.getItem('info') || '')
    } catch (e) {
      return error("Can't find user info")
    }

    const isManager = ref<boolean>(isDesignerManager(userInfo.value))
    const isDesigner = ref<boolean>(isDesignerAssignment(userInfo.value))

    const fileList = ref<FileItem[]>([])
    const data = reactive({
      isLoadingExport: false,
      isLoadingExportDropship: false,
      isLoadingImport: false,
      isLoading: false,
      showExportHistory: false,
      dataHistories: {
        histories: ref<any[]>([]),
        total: 1,
        countTotal: 1,
        params: {
          page: 1,
          limit: 10,
        },
      },
      daterange: '',
      selectedOrderId: '',
      selectedDesignerId: '',
      currentDesignerId: '',
      notes: '',
      showAssignModal: false,
      sellerTeams: ref<any[]>([]),
      designers: ref<any[]>([]),
    })

    const {
      getOrders,
      state,
      onImportCsv,
      changeDesignStatusOrder,
      syncDefaultDesignCost,
      calcOrderProfit,
    } = useOrderRepositories()

    const params = reactive<any>({
      limit: 10,
      page: 1,
      hireDesignStatus: null,
      orderDateFrom: '',
      orderDateTo: '',
      search: null,
      requestedDesign: true,
      orderDate: {
        startDate: '',
        endDate: '',
        timezone: '',
      },
    })

    watch(params, getOrders)

    const handleSelectionChange = (val: any) => {
      state.multipleSelection = val
    }

    const validateDesign = (items: any) => {
      if (!items) return false
      items = items.filter(
        (s: any) =>
          s.itemName !== 'tip' &&
          !s.artwork1 &&
          !s.artwork2 &&
          !s.artwork3 &&
          !s.artwork4
      )
      return !items || items.length <= 0
    }

    const columns = [
      {
        key: 'name',
        title: 'Order',
        dataIndex: 'orderName',
        minWidth: '12',
        slots: { title: 'customTitle', customRender: 'orderName' },
      },
      // {
      //   title: 'Transaction ID',
      //   dataIndex: 'transactionId',
      //   minWidth: '8',
      // },
      {
        title: 'FFM status',
        dataIndex: 'status',
        minWidth: '8',
        align: 'center',
      },
      {
        title: 'Type',
        dataIndex: 'orderType',
        minWidth: '5',
        align: 'center',
      },
      {
        title: 'Design Fee',
        dataIndex: 'designFee',
        minWidth: '5',
        align: 'center',
      },
      {
        title: 'Design status',
        dataIndex: 'designStatus',
        minWidth: '5',
        align: 'center',
      },
      {
        title: 'Store',
        dataIndex: 'store',
        minWidth: '5',
        align: 'center',
      },
      {
        title: 'Designer',
        dataIndex: 'designer',
        minWidth: '5',
        align: 'center',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        minWidth: '7',
        align: 'center',
      },
    ]

    const onChangeDate = async () => {
      if (data.daterange?.length > 1) {
        let startDate = moment(data.daterange[0]).format('YYYY-MM-DD')
        let endDate = moment(data.daterange[1]).format('YYYY-MM-DD')
        params.orderDate = {
          startDate: startDate,
          endDate: endDate,
          timezone: 'America/Los_Angeles',
        }
      } else {
        delete params.orderDate
      }
    }

    const { getDesigners } = useUserRepositories()

    const getDesignerList = async () => {
      let res = await getDesigners({
        page: 1,
        limit: 100000,
      })
      data.designers = res as any[]
    }

    const onShowReAssignModal = (id: string, designer: string) => {
      data.showAssignModal = true
      data.selectedOrderId = id
      data.currentDesignerId = designer

      console.log('current-data', data)
    }

    const closeAssignDialog = () => {
      data.showAssignModal = false
      data.selectedOrderId = ''
      data.currentDesignerId = ''
      data.selectedDesignerId = ''
    }

    onMounted(async () => {
      data.isLoading = true
      onLoadOrders()
      getDesignerList()
      data.isLoading = true
    })

    const onLoadOrders = async () => {
      data.isLoading = true
      await getOrders(params)
      data.isLoading = false
    }

    const onChangeFiles = (files: any) => {
      fileList.value = files
    }

    const handleSizeChange = (val: any) => {
      console.log(`${val} items per page`)
    }

    const changeFilter = () => {
      let currentPage = params.page
      let query = { ...route.query, page: currentPage + 1 }
      router.push({ query: query })
    }

    const handleCurrentChange = async (val: any) => {
      console.log(`current page: ${val}`)
    }

    const {
      validateApproved,
      validatePickup,
      validateReject,
      validateCancelShip,
      validateVerifyAddress,
      validateActionWithStatus,
      validateActionWithDesignStatus,
    } = validateOrderAction()

    const onSubmitReview = async (id: string, status: string) => {
      try {
        let updateParams = { id: id, design_status: status }
        const res = await changeDesignStatusOrder(updateParams)
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Send design to review success!`)
          onLoadOrders()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const getDefaultDesignCost = async (order: any) => {
      const hasCatalogCode =
        order.items.length > 0 &&
        order.items.every(
          (item: any) => item.catalogCode != null && item.catalogCode !== ''
        )
      if (hasCatalogCode && hasCatalogCode === true) {
        const res = await syncDefaultDesignCost(order._id)
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Send design to review success!`)
          onLoadOrders()
          // update profit
          if (state.order?.syncProfit === 1 || state.order?.syncProfit === 2) {
            calcOrderProfit(state.order?._id?.toString())
          }
        } else {
          error(res?.data?.message)
        }
        onLoadOrders()
        success('Ok')
      } else {
        error('Please maping catalog codes for all items')
        // onLoadOrders()
        // success('Ok')
      }
    }

    const onReAsignDesigner = async () => {
      try {
        let updateParams = {
          id: data.selectedOrderId,
          design_status: 'assign',
          designerId: data.selectedDesignerId,
          content: data.notes,
        }
        const res = await changeDesignStatusOrder(updateParams)
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Designer successfully reassigned!`)
          onLoadOrders()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const onFilterCallback = (data: any) => {
      // console.log('data', data)
      let search = data?.search
      params.search = search
    }

    return {
      isShowModalImport,
      content,
      columns,
      state,
      data,
      currentPage: ref(1),
      fileList,
      router,
      params,
      formatterDollars,
      formatDateTime,
      imageUrl,
      onChangeFiles,
      handleSizeChange,
      handleCurrentChange,
      changeFilter,
      onImportCsv,
      handleSelectionChange,
      onSubmitReview,
      onChangeDate,
      onFilterCallback,
      onShowReAssignModal,
      getOrderStatusColor,
      getAddressStatusColor,
      getDefaultDesignCost,
      statusTitleMap,
      designServiceTitleMap,
      statusListAgency,
      hireDesignStatus,
      addressTitleMap,
      shortcutsDatetimePicker,
      changeDesignStatusOrder,
      syncDefaultDesignCost,
      formatTime,
      getTrackingOfOrder,
      copyToClipboardV2,
      validateOrderAction,
      validateApproved,
      validateDesign,
      validateReject,
      validateCancelShip,
      validateActionWithStatus,
      validateActionWithDesignStatus,
      UserTypes,
      getFullUrlLattePrint,
      validatePickup,
      getWarningContent,
      getDesignerList,
      closeAssignDialog,
      onReAsignDesigner,
      AuthorityEnum,
      isManager,
      isDesigner,
      HIRE_DESIGN_STATUS,
    }
  },
})
